import { useState } from "react";
import NavLogo from "./NavLogo/NavLogo";
import NavLinks from "./NavLinks/NavLinks";
import NavButtons from "./NavButtons/NavButtons";
import Container from "../UI/Container/Container";

const Navbar = ({ children, handleScroll }) => {
  const navLinks = [
    {
      name: "ABSTRACT",
      link: "#",
    },
    {
      name: "COLLECTION",
      link: "#",
    },
    {
      name: "BENEFITS",
      link: "#",
    },
    {
      name: "ECOSYSTEM",
      link: "#",
    },
    {
      name: "ROADMAP",
      link: "#",
    },
    {
      name: "TEAM",
      link: "#",
    },
    {
      name: "FAQ",
      link: "#",
    },
  ];
  const navButtons = [
    {
      name: "LITEPAPER",
      link: "https://litepaper.thenftist.com/litepaper-thenftist.pdf",
    },
  ];
  const [show, setshow] = useState(false);
  return (
    <div className="w-screen overflow-x-clip">
      <div
        className={`${
          show ? "translate-x-0" : "translate-x-full"
        } fixed inset-0 overflow-y-auto h-full -left-[vw] duration-300 ease-in-out z-40 gl`}
      >
        <button
          className={`z-30 ${show ? "fixed top-2 right-10" : ""}`}
          onClick={() => {
            setshow(!show);
            console.log("hello");
          }}
        >
          {show && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              viewBox="0 0 20 20"
              fill="#ffffff"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>
        {show && (
          <div className="flex flex-col mt-20 ml-10 z-40">
            {navLinks.map((link, i) => {
              return (
                <a
                  className="mb-8 text-2xl"
                  onClick={() => {
                    console.log("aa");
                    handleScroll(`${link.name.toLowerCase()}`);
                    setshow(false);
                  }}
                  key={i}
                >
                  <h2 className="text-white font-semibold tracking-wide">
                    {link.name}
                  </h2>
                </a>
              );
            })}
            <div className="absolute bottom-20 left-1 w-full flex justify-center">
              <NavButtons className="" navButtons={navButtons} />
            </div>
          </div>
        )}
      </div>
        <div className="w-0 md:w-screen invisible md:visible absolute top-0 py-0 md:py-6 hidden md:contents">
          <div className="container py-2">
            <div className="flex justify-between">
              <NavLogo />
              <NavLinks
                handleScroll={handleScroll}
                className="w-0 md:w-auto invisible md:visible"
                navLinks={navLinks}
              />
              <NavButtons
                className="w-0 md:w-auto invisible md:visible"
                navButtons={navButtons}
              />
            </div>
          </div>
        </div>
        <div className="w-screen md:w-0 md:invisible visible absolute top-0 py-0 md:py-0 contents md:hidden">
          <div className="container py-2">
            <div className="flex justify-between items-center">
              <NavLogo />
              <button
                className={`z-30 ${show ? "fixed right-10" : ""}`}
                onClick={() => {
                  setshow(!show);
                  console.log("hello");
                }}
              >
                {!show && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10"
                    viewBox="0 0 20 20"
                    fill="#ffffff"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      {children}
    </div>
  );
};

export default Navbar;
